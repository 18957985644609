import { Label, Image, Card, Button } from 'semantic-ui-react'
import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
import qs from 'qs'
import ImageLoad from './LazyImage'

const tagFilters = ["Men's", "Women's"]


class Feed extends Component {
    constructor(props) {
      super(props)

      this.state = {
        tags : null,
        active: "",
        
        
        
      }

    

  
    }

    componentDidMount() {
      if (this.props.collections < 1 ) {
        this.props.getColl()
      }

      if(this.props.filteredColl.length >= 1 ) {
        
        this.handleClickFilter("")
      }
    }

    handleClickFilter = (filter) => {
      this.setState({ active : filter })
      this.props.filterCollection(filter)
     }

    



   
    



    render() {
    const { collections, filteredColl, filterCollection } = this.props
    const { active } = this.state

    

    return (

      <div className="wrapper">
    <nav className="nav-side" role='navigation'>
    <NavLink to="/"><img className="side-logo" src="https://mixcart.s3.us-east-2.amazonaws.com/mixcartlogo.svg"/></NavLink>
    <div className="inactive-icon-div"><NavLink exact to="/search" activeClassName="active-nav-icon"><i className="fas fa-search"></i></NavLink></div> 
    <div className="inactive-icon-div"><NavLink exact to="/shuffle" activeClassName="active-nav-icon"><i className="fas fa-random"></i></NavLink></div> 
    <div className="logout-icon-div"><Label size="small" basic >BETA</Label></div>
    </nav>
     
      <section className="section-main-discover">
      <div className="page-header-text"><h1>Shuffle</h1>
      <p className="page-header-sub">Browse curated collections of shops in different categories</p></div>
      <div className="coll-button-group">
      <Button onClick={() => this.handleClickFilter("")} basic active={active === "" ? true : false} >
      All
    </Button>
    <Button onClick={() => this.handleClickFilter("Men's")} basic  active={active === "Men's" ? true : false} >
      Men's
    </Button>
    <Button basic onClick={() => this.handleClickFilter("Women's")} active={active === "Women's" ? true : false} >
      Women's
    </Button>
    
    </div>
    
  
      <div className ="card-wrapper">
     
   

      
      <Card.Group stackable itemsPerRow={4}>
      
      {filteredColl.length >= 1 ? (filteredColl.map((collection, index) => (
                
                <Card key={collection._id} as={NavLink}  
                to={collection.filters ? ({ pathname: `/shop/${collection._id}/${encodeURIComponent(collection.shops[0].shopName)}/0` , search: qs.stringify({filter: collection.filters})}) :
                ({ pathname: `/shop/${collection._id}/${encodeURIComponent(collection.shops[0].shopName)}/0`})
                  }>

               
                <Image fluid src={collection.collectionImg}
               
                />

                <Card.Content>
      <Card.Header>{collection.collectionName}</Card.Header>
      <Card.Meta>
      
        <span className='stores-counter'>{ <>
  <i className="fas fa-shopping-bag fa-sm"></i> &nbsp;
  {collection.shops.length} Shops
</>}</span></Card.Meta>

    </Card.Content>
    
  
 
                
              
                
              </Card>
              ))) : (collections.map((collection, index) => (
                
                <Card key={collection._id} as={NavLink}  
                to={collection.filters ? ({ pathname: `/shop/${collection._id}/${encodeURIComponent(collection.shops[0].shopName)}/0` , search: qs.stringify({filter: collection.filters})}) :
                ({ pathname: `/shop/${collection._id}/${encodeURIComponent(collection.shops[0].shopName)}/0`})
                  }>
                   
               
                <ImageLoad imgsrc={collection.collectionImg}/>
               
           
                <Card.Content>
      <Card.Header>{collection.collectionName}</Card.Header>
      <Card.Meta>
      
        <span className='stores-counter'>{ <>
  <i className="fas fa-shopping-bag fa-sm"></i> &nbsp;
  {collection.shops.length} Shops
</>}</span>

      </Card.Meta>

    </Card.Content>
   
         

               
                
                
              </Card>
              )))}
            
   
    
    </Card.Group>
    </div>
      
      </section>
   </div>
      
    )
    }
  }
  
  export default Feed;

  /* Extra Card Tags

         <Card.Content extra>
                <Label.Group size="mini">
                {collection.tags ? (
                   collection.tags.map((tag, index) => (
                    <Label key={index} >
                      {tag}
                      </Label>
                   ))
                ): ("")}
               
             
                
                </Label.Group>
    </Card.Content>



      <Card.Content>
      <Card.Header>{collection.collectionName}</Card.Header>
      <Card.Meta>
      
        <span className='stores-counter'>{ <>
  <i className="fas fa-shopping-bag fa-sm"></i> &nbsp;
  {collection.shops.length} Shops
</>}</span>

      </Card.Meta>
  

    </Card.Content>
    <Card.Content extra>
    <Label  size="tiny" basic key={index} >
                      <Icon name={collection.filters === "Men's" ? "male":"female"}></Icon>
                      {collection.filters}
                      </Label>
                    
            
    </Card.Content>
    */