import React from 'react';
import { Placeholder } from 'semantic-ui-react'


class ImageLoader extends React.Component {
    constructor(){
      super();
      this.state = {loaded: false};
    }
  
    render(){
      return (
        <>
        {!this.state.loaded && <Placeholder>
          <Placeholder.Image square />
        </Placeholder>}
        
          <img
            className="product-image"
            style={this.state.loaded ? {
                height: 'auto'} : {display: 'none'}}
            src={this.props.imgsrc}
            onLoad={() => this.setState({loaded: true})}
          />

        
        </>
      );
    }
  }

  export default ImageLoader
