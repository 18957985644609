import React, {Component} from 'react';
import { Dimmer, Loader } from 'semantic-ui-react'



class RedirectCompo extends Component {

    componentDidMount() {

        setTimeout(() => this.myFunction(), 450)
      }
      myFunction = () => {
        window.location.replace(decodeURIComponent(this.props.match.params.redirectUrl));
    
              }
    

  
    render() {
    return (
     
        <section className="section-main">
            
    
        <Dimmer active inverted>
        <Loader size='large'>Redirecting to { new URL(decodeURIComponent(this.props.match.params.redirectUrl)).hostname} <br></br> <br></br>
        Click <a href={decodeURIComponent(this.props.match.params.redirectUrl)}>here</a> if not automatically redirected</Loader>
      </Dimmer>
        
        </section>
      
    );
    }
  }
  
  export default RedirectCompo;

  