import React from 'react'


const SvgCompo = ( { fill, viewBox, d, d2, fill2 } ) => (
   
   <svg xmlns="http://www.w3.org/2000/svg" height={"35px"} viewBox={viewBox}>
            <path fill={fill}  d={d}></path>
            {d2 ? <path fill={fill2 ? fill2 : fill}  d={d2}></path> : null }
          </svg>
    
  );

  export default SvgCompo