import React, { useState, useEffect } from 'react';
import { Label , Header, Card, Image, Icon, Button } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion"
import shuffle from 'lodash/shuffle'



const spring = {
  type: "spring",
  damping: 90,
  stiffness: 90
};


const initialLogos = [
  "https://cdn.shopify.com/s/files/1/0884/2128/files/LOGO1_155x@2x.png?v=1541835218",
  "https://cdn.shopify.com/s/files/1/0732/9117/t/25/assets/logo-dark.png?v=8690074996635929464",
  "https://cdn.shopify.com/s/files/1/0359/8357/files/Faherty_logo_icon_aa98c690-5468-44f9-a371-6072d187d837_410x.png?v=1563335835",
  "https://cdn.shopify.com/s/files/1/2445/4975/t/421/assets/logo_300x.png?v=3521177642543458065",
  "https://cdn.shopify.com/s/files/1/0223/5177/t/108/assets/logo.png?v=9682297967558455436",
  "https://cdn.shopify.com/s/files/1/1740/3191/files/pc_black_165x.png?v=1486082189",
  "https://cdn.shopify.com/s/files/1/1514/6262/files/Bohme-Logo-2019-Black_175x@2x.png?v=1553121114",
  "https://cdn.shopify.com/s/files/1/1970/3937/files/JW-Hulme-logo-black_250x.png?v=1567002553",
  "https://cdn.shopify.com/s/files/1/0155/4295/files/logo_150x.png?v=1569479130",
  "https://cdn.shopify.com/s/files/1/0279/7607/t/37/assets/i-logo-black.png?v=16506780768906331205",
  "https://cdn.shopify.com/s/files/1/2580/9618/files/Logo_Final_-_Remu_Apparel_140x.png?v=1573807073",
  "https://d1lztky2c7u3j6.cloudfront.net/eyJidWNrZXQiOiJzaHVmZmxlaW1nIiwia2V5IjoiZG93bmxvYWQucG5nIn0=",
  "https://cdn.shopify.com/s/files/1/0964/4354/files/logo_260x.png?v=1501720540",
  "https://cdn.shopify.com/s/files/1/0236/4195/t/25/assets/wool_prince_logo.svg?v=15040485272625307248"




];



const Home = () => {

  const [colors, setColors] = useState(initialLogos);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setColors(shuffle(colors)), 4000);
      

    return () => {
      clearTimeout(timer1)
    }
  
  }, [colors]);


  
    return (
        <div className="home-page-div">
            <div className="home-page-header">
            <img className="login-logo" src="https://shuffleimg.s3.us-east-2.amazonaws.com/mxlogo1.svg" alt="logo"></img> 
            <Label size="small" basic >BETA</Label>
            </div>
            <div className="home-page-hero">
            <div className="home-page-hero-div">
            <Header className="home-page-head" >


           
    Discover your new <br></br> favorite brand
    <Header.Subheader style={{ 
          marginTop: ".5em"

    }}>
      Search or shuffle through our curated e-commerce marketplace
    </Header.Subheader>
  </Header>
  <div className="home-page-buttons">
  <Button primary as={NavLink} to="/search" >
  <Icon  name='search' />
        Search
      </Button>
      <Button  as={NavLink} to="/shuffle" >
      <Icon  name='random' />
        Shuffle
      </Button>
      </div>
      



  </div>
  <div className="home-page-card">

  <ul>
 
        {colors.slice(0,6).map(background => (
          <motion.li className="test-li" key={background} layoutTransition={spring}>
            <img src={background} alt="lo" />
          </motion.li>
        ))
      }


    </ul>
  
  
  
      
 
 
  </div>
            </div>
            
            <div className="home-page-works">

            <Header style={{
        fontSize: '2em',
        color: "#424242"
        
        
      }} >


           
    How it works
    <Header.Subheader>
      A few different ways to discover something new
    </Header.Subheader>
  </Header>
  </div>
  <div className="home-page-works-card-div">
    <div className="home-page-works-card" >
    <NavLink to="/search">
   <img className="home-page-works-icon" src="https://shuffleimg.s3.us-east-2.amazonaws.com/icons8-add_shop-lb.svg" alt="shop"></img>
   </NavLink>
   <Header style={{
        fontSize: '1em',
        color: "#424242"
        
        
      }} >


           
    Curate
    <Header.Subheader className="header-sub">
We aggregate our favorite emerging stores into an easy to browse marketplace </Header.Subheader>
  </Header>
    </div>
    <div className="home-page-works-card" >
    <NavLink to="/search">
    <img className="home-page-works-icon" src="https://shuffleimg.s3.us-east-2.amazonaws.com/icons8-search.svg" alt="shop"></img>
    </NavLink>
   <Header style={{
        fontSize: '1em',
        color: "#424242"
        
        
      }} >


           
    Search
    <Header.Subheader className="header-sub">
Use the global search to find and filter products from all of our stores at once  </Header.Subheader>
  </Header>
      
      </div>
      <div className="home-page-works-card" >
      <NavLink to="/shuffle">
      <img className="home-page-works-icon" src="https://shuffleimg.s3.us-east-2.amazonaws.com/icons8-shuffle.svg" alt="shop"></img>
      </NavLink>
   <Header style={{
        fontSize: '1em',
        color: "#424242"
        
        
      }} >


           
    Shuffle
    <Header.Subheader className="header-sub">
Discover new brands by browsing through curated collections </Header.Subheader>
  </Header>
      
      </div>

  </div>
  <div className="home-page-why-div">
  <div className="home-page-why-img">
  <div className="home-page-why-img-bar">
  <i className="fas fa-arrow-alt-circle-left"></i>
    <h6>Men's Sustainable Basics</h6>

  <i className="fas fa-arrow-alt-circle-right"></i>
  </div>
    <img className="home-page-why-embed" src="https://d1lztky2c7u3j6.cloudfront.net/eyJidWNrZXQiOiJzaHVmZmxlaW1nIiwia2V5IjoiU2NyZWVuIFNob3QgMjAyMC0wMS0yNiBhdCA2LjA0LjIxIFBNLnBuZyJ9" alt="shop"></img>


  </div>

  <div className="home-page-why-text">
  <Header style={{
        fontSize: '1.6em',
        color: "#424242"
        
        
      }} >


           
    Why mixcart?
    <Header.Subheader style={{ 
          marginTop: ".5em"

    }}>
    With millions of e-commerce sites, some of the worlds best brands and products go largely unnoticed. Using a mixture of human and algorithmic curation, mixcart aggregates the best stores the internet has to offer and provides an immersive shopping discovery experience
     </Header.Subheader>
     <div className="shop-now">
      <NavLink to="/search">
     <strong >Shop Now
     <i className="fas fa-long-arrow-alt-right"></i>
     </strong> 
     </NavLink>
     </div>
     
  </Header>

    

    </div>


  </div>
  <div className="home-page-why-div-2">
  <div className="home-page-why-text-2">
  <Header style={{
        fontSize: '1.6em',
        color: "#424242"
        
        
      }} >


           
    A collection for everyone
    <Header.Subheader style={{ 
          marginTop: ".5em"

    }}>
    Curated collections of shops are updated daily. From <b>Women's Sustainable Basics</b> to <b>Guys Workday Wear</b> find shops
    suited to your style and taste
     </Header.Subheader>
     <div className="shop-now-2">
      <NavLink to="/shuffle">
     <strong >Shuffle Now
     <i className="fas fa-random"></i>
     </strong> 
     </NavLink>
     </div>
     
  </Header>

    

    </div>
  <div className="home-page-why-img-2">

  <Card>

               
                <Image fluid src="https://d1lztky2c7u3j6.cloudfront.net/eyJidWNrZXQiOiJzaHVmZmxlaW1nIiwia2V5IjoiNWUwYTVlOTIyZTRhYmE2MDE5YWM4NzZkLWNvbnRlbnQtcGl4aWUtX3lzVTdUcUJpWkktdW5zcGxhc2guanBlZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NTAwLCJoZWlnaHQiOjUwMCwiZml0IjoiY292ZXIifX19"
               
                />

                <Card.Content>
      <Card.Header>Women's Sustainable Basics</Card.Header>
      <Card.Meta>
      
        <span className='stores-counter'>{ <>
  <i className="fas fa-shopping-bag fa-sm"></i> &nbsp;
  6 Shops
</>}</span></Card.Meta>

    </Card.Content>
    
  
 
                
              
                
              </Card>



  </div>

 


  </div>
            
        </div>
     
        
    );
    
  }
  
  export default Home;

  /* <video id="sampleMovie" width="640" height="350" autoPlay loop muted playsInline>
    <source src="https://shuffleimg.s3.us-east-2.amazonaws.com/shuffle.mov" />
 
</video>

 <Card fluid >
    <Image src='https://shuffleimg.s3.us-east-2.amazonaws.com/Screen+Shot+2020-01-25+at+3.18.41+PM.png'/>
    


 

 

  </Card>

  <motion.div   whileHover={{
      scale: 1.05
    }}
   className="logo-circle">
    <Image size="tiny" src='https://cdn.shopify.com/s/files/1/0732/9117/t/25/assets/logo-dark.png?v=8690074996635929464' />

        
     
</motion.div>
  <div className="logo-circle">
    <Image size="tiny" src='https://cdn.shopify.com/s/files/1/0359/8357/files/Faherty_logo_icon_aa98c690-5468-44f9-a371-6072d187d837_410x.png?v=1563335835' />

        
      </div>

      <div className="logo-circle">
    <Image size="tiny" src='https://cdn.shopify.com/s/files/1/0884/2128/files/LOGO1_155x@2x.png?v=1541835218' />

        
      </div>

*/