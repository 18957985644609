
import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'

const BreadcrumbMenu = ({ shopName, collectionName }) => (
    
          <Breadcrumb size="small">
<Breadcrumb.Section>{collectionName}</Breadcrumb.Section>
            <Breadcrumb.Divider icon='angle double right' />
<Breadcrumb.Section active>{shopName}</Breadcrumb.Section>
          </Breadcrumb>
      
    
  )
  
  export default BreadcrumbMenu