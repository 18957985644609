import React, {useEffect, useState } from 'react';
import { Container, Label, Loader, Button, Modal , Icon } from 'semantic-ui-react'
import { NavLink, Link } from "react-router-dom";
import './Product.css';
import DOMPurify from 'dompurify'
import qs from 'qs'
import ImageLoader from './ImageLoader'
import ConfirmModal from './ConfirmModal'
import { useMediaQuery } from 'react-responsive'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
//import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
//import 'pure-react-carousel/dist/react-carousel.es.css';


const ProductDetail = (props) => {

    const [product, setProduct] = useState(null);
    const [imageIndex , setImageIndex] = useState(0);
    const [currentVariantIndex, setCurrentVariantIndex] = useState(null)
    const [currentVariantId, setCurrentVariantId] = useState(null)
    const [select, setSelect] = useState({})
    const [vMap, setVMap] = useState(null)
    const [colorOptions, setColorOptions] = useState(null)
    const [selectError, setSelectError] = useState(false)
    const [newImages, setNewImages] = useState(null)
    const [initialImage, setInitialImage] = useState(null)

    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-width: 900px)' })


    useEffect(() => {
        let url = `https://${decodeURIComponent(props.match.params.shop)}/products/${decodeURIComponent(props.match.params.handle)}/product.json`
        fetch(url).then(
            response => response.json()).then(
            json => {

              let objClone = { ...json.product };
              let variantMap = {}
              let variantQuery = Number(props.match.params.variant)
              let currV = null

              setProduct(objClone)

              for(var i = 0; i < objClone.images.length ; i++) {
                if (objClone.images[i].variant_ids.includes(variantQuery)){
                    setImageIndex(i)
                    setInitialImage(i)
                    break
                }
            }
              for(var i = 0; i < objClone.variants.length ; i++) {

                let optionsArr = [objClone.variants[i].option1 , objClone.variants[i].option2 , objClone.variants[i].option3].filter(Boolean).join('')

                variantMap[optionsArr] = objClone.variants[i].id
                
            }

            setVMap(variantMap)

                for(var v = 0; v < objClone.variants.length ; v++){
  
                  if(objClone.variants[v].id === variantQuery) {
  
                      console.log(`setting variant ID to ${v} because match`)
                      setCurrentVariantIndex(v)
                      currV = v
                      break
                      
  
                  }
  
  
              }

              if (currV !== null && objClone.options.length >= 1 && variantMap) {

                let oldSelect = {...select}

                for(var o = 0; o < objClone.options.length ; o++){


                    if ((objClone.options[o].name.toLowerCase() === "color" && o === 0 ) ) {
                        oldSelect["color"] = objClone.variants[currV].option1
                        setSelect(oldSelect)
                        setColorOptions(objClone.options[0].values)
                        setCurrentVariantId(variantMap[objClone.variants[currV].option1])
                        findImages(objClone.options[0].values, objClone.images, objClone.variants[currV].option1 )
                        break

                    }

                    if ((objClone.options[o].name.toLowerCase() === "color") && o === 1 ) {
                        oldSelect["color"] = objClone.variants[currV].option2
                        setSelect(oldSelect)
                        setColorOptions(objClone.options[1].values)
                        setCurrentVariantId(variantMap[objClone.variants[currV].option2])
                        findImages(objClone.options[1].values, objClone.images, objClone.variants[currV].option2 )
                        break
                    }

                    if ((objClone.options[o].name.toLowerCase() === "color" ) && o === 2 ) {
                        oldSelect["color"] = objClone.variants[currV].option3
                        setSelect(oldSelect)
                        setColorOptions(objClone.options[2].values)
                        setCurrentVariantId(variantMap[objClone.variants[currV].option3])
                        findImages(objClone.options[2].values, objClone.images, objClone.variants[currV].option3 ) 
                        break
                    }


                }


              }
        })
          

        
      },[]);

      function findImages(colorOptions, images, match) {
          let newImgs = []
          let variantLength = null
            for (const item of images) {
              if (
                item.alt && (item.alt.includes(match) && colorOptions.every(color => color === match || !item.alt.includes(color)))
              ) {
                newImgs.push(item) 

              }
              if (item.variant_ids.length >= 1) {
                  variantLength = variantLength + item.variant_ids.length
                  

              }
            }

            console.log(variantLength)

            if (variantLength !== null){
                setNewImages(newImgs)
            } else {
                setNewImages(images)
            }
            
            
      }

      function selectedTrigger (toAdd, name) {

        let oldTrigger = {...select}

        oldTrigger[name] = toAdd

        setSelect(oldTrigger)

        if (product && product.options.length === 1 && vMap) {

            setCurrentVariantId(vMap[toAdd])
        
        } 

        if (product && product.options.length === 2 && vMap) {

            let keyObj = Object.keys(oldTrigger)

            console.log(keyObj.length)

            if (keyObj.length === 2) {

                if (selectError) {
                    setSelectError(null)

                }

                setCurrentVariantId(vMap[oldTrigger[product.options[0].name.toLowerCase()] + oldTrigger[product.options[1].name.toLowerCase()]])

            }

            

        }

        if (product && product.options.length === 3 && vMap) {

            console.log(oldTrigger)

            let keyObj = Object.keys(oldTrigger)

            console.log(keyObj)

            console.log(keyObj.length)

            if (keyObj.length === 3) {

                if (selectError) {
                    setSelectError(null)

                }

                

                setCurrentVariantId(vMap[oldTrigger[product.options[0].name.toLowerCase()] + oldTrigger[product.options[1].name.toLowerCase()] + oldTrigger[product.options[2].name.toLowerCase()]])

            }

            

        }



        

      }

 

  




    return (
        <div className="wrapper">
        <nav className="nav-side" role='navigation'>
        <NavLink to="/"><img className="side-logo" src="https://mixcart.s3.us-east-2.amazonaws.com/mixcartlogo.svg"/></NavLink>
        <div className="inactive-icon-div"><NavLink exact to="/search" activeClassName="active-nav-icon"><i className="fas fa-search"></i></NavLink></div> 
        <div className="inactive-icon-div"><NavLink exact to="/shuffle" activeClassName="active-nav-icon"><i className="fas fa-random"></i></NavLink></div> 
        <div className="logout-icon-div"><Label size="small" basic >BETA</Label></div>
        </nav>
         
          <section className="section-product-info">

        

          {product && (
          <div className="product-info-wrapper">

<div className="product-images-small">
              {newImages && newImages.length >= 1 ? (newImages.map((image, index) => (

                <div key={index} className="product-image-small-div" onClick={()=> setImageIndex(image.position - 1)}>
                <img className="product-image-small" src={image.src} alt="thumb"></img>
                </div>

               
                 ))) : (

                    <div className="product-image-small-div">
                    <img className="product-image-small" src={product.images[imageIndex].src} alt="thumb"></img>
                    </div>

              ) }
              
              
              
                  
              </div>

              <div className="product-image-container">

              <Carousel
              showStatus={false}
              showThumbs={(isTabletOrMobileDevice && newImages && newImages.length >= 1) ? true : false}
              showIndicators={false}
              showArrows={(isTabletOrMobileDevice && newImages && newImages.length >= 1) ? true : false}
              dynamicHeight={(isTabletOrMobileDevice && newImages && newImages.length >= 1) ? true : false}
              >
                  {(isTabletOrMobileDevice && newImages && newImages.length) >= 1 ? (

            newImages.map((img, ind) => (

                <div key={ind}>
                <img className="product-image" src={img.src}/>

                </div>
               

                )


                )




                ) : (
                    <div><img className="product-image" src={product.images[imageIndex].src}/></div>)}

            
                

               

            </Carousel>
             
            
       
      </div>

              <div className="product-details">
           
   

       
    
    <h1>{product.title}</h1>
    <h4>{props.match.params.brand}</h4>
    {currentVariantIndex !== null && (
        <>
        <h3>{`$${product.variants[currentVariantIndex].price}`}</h3>
        </>
    )}

    {product.options.length > 0 && (
        <>
    {product.options.map((option, index) => (
  <div key={index} className="product-options">
    <h5>{option.name}</h5>
    {option.values.map((o, i) => (
        <>
        { (select[option.name.toLowerCase()] === o || option.name.toLowerCase() !== "color") && (

<Button size="tiny" basic active={select[option.name.toLowerCase()] === o  ? true : false  } onClick={()=> selectedTrigger(o,option.name.toLowerCase())} key={i + o}>{o}</Button>


        )
        
        }
                

        
        
        

        </>
    ))}
    
  </div>
))}



</>
    )}
            
              
              <div className="product-options" dangerouslySetInnerHTML={{
    __html: DOMPurify.sanitize(product.body_html, {FORBID_ATTR: ['style']})
   
}} />

{selectError && (<h4>{selectError}</h4>)}
<div className="checkout-button">
{currentVariantIndex !== null && (
    <>
{product.variants[currentVariantIndex].inventory_quantity ? (

    
product.variants[currentVariantIndex].inventory_quantity >= 0 ? (

    <ConfirmModal shopUrl={props.match.params.shop} vPrice={currentVariantIndex !== null && product.variants[currentVariantIndex].price} inventoryQ={(currentVariantIndex !== null) ? (product.variants[currentVariantIndex].inventory_quantity ? product.variants[currentVariantIndex].inventory_quantity : 5)  : 5 } variantId={currentVariantId} setError={setSelectError} selectedV={select} productName={product.title} productBrand={props.match.params.brand} vImages={product.images} imgIndex={initialImage}/>



) : (

    <Button disabled fluid>Sold Out</Button>
)





) : (

    <ConfirmModal shopUrl={props.match.params.shop} vPrice={currentVariantIndex !== null && product.variants[currentVariantIndex].price} inventoryQ={(currentVariantIndex !== null) ? (product.variants[currentVariantIndex].inventory_quantity ? product.variants[currentVariantIndex].inventory_quantity : 5)  : 5 } variantId={currentVariantId} setError={setSelectError} selectedV={select} productName={product.title} productBrand={props.match.params.brand} vImages={product.images} imgIndex={initialImage}/>






)}



    </>


)}
</div>
            

            
  
  </div>
              
              </div>) }
          
              
         
     
          
          </section>
       </div>
    )
  };




export default ProductDetail
//<img className="product-image" src={product.images[imageIndex].src} alt="product-image" onLoad={()=>setLoaded(true)}></img>                  
//<Button as='a'  href={`https://${props.match.params.shop}/cart/${currentVariantId}:1`} fluid primary >Checkout</Button>
//product.variants[currentVariantIndex].inventory_quantity && product.variants[currentVariantIndex].inventory_quantity >= 0
//<ConfirmModal shopUrl={props.match.params.shop} vPrice={currentVariantIndex !== null && product.variants[currentVariantIndex].price} inventoryQ={(currentVariantIndex !== null) ? (product.variants[currentVariantIndex].inventory_quantity ? product.variants[currentVariantIndex].inventory_quantity : 5)  : 5 } variantId={currentVariantId} setError={setSelectError} selectedV={select} productName={product.title} productBrand={props.match.params.brand} vImages={product.images} imgIndex={initialImage}/>
//<Button disabled fluid>Sold Out</Button>


/*

 <div className="wrapper">
        <nav className="nav-side" role='navigation'>
        <NavLink to="/"><img className="side-logo" src="https://mixcart.s3.us-east-2.amazonaws.com/mixcartlogo.svg"/></NavLink>
        <div className="inactive-icon-div"><NavLink exact to="/search" activeClassName="active-nav-icon"><i className="fas fa-search"></i></NavLink></div> 
        <div className="inactive-icon-div"><NavLink exact to="/shuffle" activeClassName="active-nav-icon"><i className="fas fa-random"></i></NavLink></div> 
        <div className="logout-icon-div"><Label size="small" basic >BETA</Label></div>
        </nav>
         
          <section className="section-product-info">

        

          {product && (
          <div className="product-info-wrapper">
              <div className="product-images-small">
              {newImages && newImages.length >= 1 ? (newImages.map((image, index) => (

                <div key={index} className="product-image-small-div" onClick={()=> setImageIndex(image.position - 1)}>
                <img className="product-image-small" src={image.src} alt="thumb"></img>
                </div>

               
                 ))) : (

                    <div className="product-image-small-div">
                    <img className="product-image-small" src={product.images[imageIndex].src} alt="thumb"></img>
                    </div>

              ) }
              
              
              
                  
              </div>
              <div className="product-image-container">
              <ImageLoader imgsrc={product.images[imageIndex].src}/>
              </div>

              <div className="product-details">
           
   

       
    
    <h1>{product.title}</h1>
    <h4>{props.match.params.brand}</h4>
    {currentVariantIndex !== null && (
        <>
        <h3>{`$${product.variants[currentVariantIndex].price}`}</h3>
        </>
    )}

    {product.options.length > 0 && (
        <>
    {product.options.map((option, index) => (
  <div key={index} className="product-options">
    <h5>{option.name}</h5>
    {option.values.map((o, i) => (
        <>
        { (select[option.name.toLowerCase()] === o || option.name.toLowerCase() !== "color") && (

<Button size="tiny" basic active={select[option.name.toLowerCase()] === o  ? true : false  } onClick={()=> selectedTrigger(o,option.name.toLowerCase())} key={i + o}>{o}</Button>


        )
        
        }
                

        
        
        

        </>
    ))}
    
  </div>
))}



</>
    )}
            
              
              <div className="product-options" dangerouslySetInnerHTML={{
    __html: DOMPurify.sanitize(product.body_html, {FORBID_ATTR: ['style']})
   
}} />

{selectError && (<h4>{selectError}</h4>)}
<div className="checkout-button">
{currentVariantIndex !== null && (
    <>
{product.variants[currentVariantIndex].inventory_quantity ? (

    
product.variants[currentVariantIndex].inventory_quantity >= 0 ? (

    <ConfirmModal shopUrl={props.match.params.shop} vPrice={currentVariantIndex !== null && product.variants[currentVariantIndex].price} inventoryQ={(currentVariantIndex !== null) ? (product.variants[currentVariantIndex].inventory_quantity ? product.variants[currentVariantIndex].inventory_quantity : 5)  : 5 } variantId={currentVariantId} setError={setSelectError} selectedV={select} productName={product.title} productBrand={props.match.params.brand} vImages={product.images} imgIndex={initialImage}/>



) : (

    <Button disabled fluid>Sold Out</Button>
)





) : (

    <ConfirmModal shopUrl={props.match.params.shop} vPrice={currentVariantIndex !== null && product.variants[currentVariantIndex].price} inventoryQ={(currentVariantIndex !== null) ? (product.variants[currentVariantIndex].inventory_quantity ? product.variants[currentVariantIndex].inventory_quantity : 5)  : 5 } variantId={currentVariantId} setError={setSelectError} selectedV={select} productName={product.title} productBrand={props.match.params.brand} vImages={product.images} imgIndex={initialImage}/>






)}



    </>


)}
</div>
            

            
  
  </div>
              
              </div>) }
          
              
         
     
          
          </section>
       </div>





*/


/* inbetween product-image-container

       <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={(isTabletOrMobileDevice && newImages && newImages.length >= 1) ? newImages.length : 1 }
        dragEnabled={(isTabletOrMobileDevice && newImages && newImages.length >= 1) ? true : false}
        hasMasterSpinner={true}
      >
          <div className="slider-and-buttons">
        <Slider className="slider-images">
        {(isTabletOrMobileDevice && newImages && newImages.length) >= 1 ? (

            newImages.map((img, ind) => (

                <Slide key={ind} index={ind}><Image className="product-image" src={img.src} /></Slide>)


            )




        ) : (<Slide index={0}><Image className="product-image" src={product.images[imageIndex].src} /></Slide>)}
          
        </Slider>
        {(isTabletOrMobileDevice && newImages && newImages.length > 1) && (
            <>
        <ButtonBack className="slider-back"><Icon name="angle left" size="large"></Icon></ButtonBack>
        <ButtonNext className="slider-next"><Icon name="angle right" size="large"></Icon></ButtonNext>
       
        </>
          )
        }
        
        </div>
      </CarouselProvider>


*/