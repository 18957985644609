import React from 'react'
import { Modal, Button, Image, Header, Input, Label, Icon } from 'semantic-ui-react'


class ConfirmModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      counter: 1,
      alertTrigger: null
      
    }
    this.setError = this.props.setError
  }




  close = () => this.setState({ openModal: false })

  checkVariant = () => {

    if (this.props.variantId) {
        this.setState({ openModal: true })

    } else {


            this.setError(`Please select a product option`)


    }


  }






  handleCounter = (e) => {

    if (this.state.alertTrigger){
        this.setState({ alertTrigger : false });

    }


    if(this.state.counter === "" || this.state.counter < 1 ) {

        this.setState({ counter : 1 });


    }else{
        this.setState((prevState, props) => ({
            counter: parseInt(prevState.counter) + 1
        }))

    }

    

}

handleCounterInput = (e) => {

    if (this.state.alertTrigger){
        this.setState({ alertTrigger : false });

    }

    let newNum = e.target.value.replace(/\D/,'')

   

        this.setState({ counter : newNum });


    

    console.log(newNum)

    

    
  

}


handleCheckoutLink = () => {

    console.log(this.state.counter === "")
    console.log(this.state.counter)

    if(this.state.counter === "" || this.state.counter < 1 ) {

        this.setState({ alertTrigger : `Please enter a valid quantity` });

    } else if (this.state.counter > this.props.inventoryQ){

        if (this.props.inventoryQ === 5) {

            this.setState({ alertTrigger : `Maximum of 5 quantity please select fewer` });
           



        }else {

            this.setState({ alertTrigger : `Maximum of ${this.props.inventoryQ} quantity please select fewer` });
            
        }
        


    } else {


        window.location.href = `https://${this.props.shopUrl}/cart/${this.props.variantId}:${this.state.counter}`
    }


    
    

    
  

}


  render() {
    const { shopUrl, selectedV, productName, vImages, imgIndex, productBrand, vPrice} = this.props
    const { openModal, counter, alertTrigger,  } = this.state
  
    return (
        <Modal basic closeIcon centered={false} open={openModal} onClose={this.close} trigger={<Button fluid primary onClick={this.checkVariant}> Checkout</Button>}>
        <Modal.Header>Confirm Purchase </Modal.Header>
        <Modal.Content image>
            <Image wrapped size='medium' src={vImages[imgIndex] ? vImages[imgIndex].src : vImages[0].src}/>
          <Modal.Description>
                    

                     <Header as='h2'> {productName && productName}</Header>
                     <h5 className="product-subheader-price">{vPrice && `$${vPrice}`}</h5>
                     <h4 className="product-subheader">{productBrand && productBrand}</h4>
                     <div className='ship-label'>
                     <Label basic size="small">
                     <Icon name='cart' />
                    {`Sold and Shipped by ${shopUrl}`}
                    </Label>
                     </div>
                     <div className="quantity-button">
                     <h5 className="quantity-header-bottom">Quantity</h5>
                     

                    <Input  onChange={this.handleCounterInput} size='small' action={{  icon: 'add', color: 'blue',  size:'mini', onClick: () => this.handleCounter() }} value={counter} />
                    {alertTrigger && <Label basic color='red' pointing='left'>
                        {alertTrigger}
                    </Label>}
                    
                    
                    </div>

                     
                     
                   
           

                     
                     {selectedV && Object.values(selectedV).map((option, index) => (
                         <h5 className="quantity-header-top" key={index}>{option}</h5>

                     ))}
                    
                


                    
                        
                    

                        

 
                 
                  
                    
            
           
          </Modal.Description> 
          
          
         
         
        </Modal.Content>
        <Modal.Actions>
       

                
                <Button basic  onClick={this.close}>
                  Go Back
                </Button>
                <Button
                  primary
                  icon='angle double right'
                  labelPosition='right'
                  content={`Checkout`}
                  onClick={this.handleCheckoutLink}
                />
              </Modal.Actions>
      </Modal>
    
    )
  }
}

export default ConfirmModal