import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { Label, Icon } from 'semantic-ui-react';



const CurrentRefinements = ({ items, refine }) => (
 <>
 {items.length >= 1  ? (
      items.map((item,index) => (
        <>
          {item.items ? (

item.items.map((nested) => (
  <Label basic key={nested.label} size="tiny" >
              {nested.label}
              <Icon key={nested.label} onClick={event => {
                event.preventDefault();
                refine(nested.value);
              }} name='close' />
            
            </Label>
))


          ) 
          
          
          : (<Label basic key={item.value} size="tiny" >
              {item.currentRefinement}
              <Icon key={index} onClick={event => {
                event.preventDefault();
                refine(item.value);
              }} name='close' />
            
            </Label>

)}

        
              
              
           
       </> 
      )) ) : <div></div>}
   </>
  );

  const CustomRefinements = connectCurrentRefinements(CurrentRefinements);

  export default CustomRefinements