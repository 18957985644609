import { Button, Modal, Icon, Header, Image} from 'semantic-ui-react'
import React, {Component} from 'react';


class ShuffleModal extends Component {
  state = { open: false }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  render() {
    const { open } = this.state

    return (
      <section className="section-main-discover">
      <div className="page-header-text"><h1>Shuffle</h1>
      <p className="page-header-sub">Coming Soon!</p></div>
      <Modal
      open={open}
      onOpen={this.open}
      onClose={this.close}
      dimmer="inverted"
      basic 
      size='fullscreen'>
     
     <Image size='small' src='https://shuffleimg.s3.us-east-2.amazonaws.com/mixcart_logo.svg' />
     
   
    <Modal.Content>
    <Image centered size='large' src='https://shuffleimg.s3.us-east-2.amazonaws.com/ezgif.com-video-to-gif.gif' />

      <p>
        Your inbox is getting full, would you like us to enable automatic
        archiving of old messages?
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button color='red'>
        <Icon name='remove' /> No
      </Button>
      <Button onClick={this.close} color='green' inverted>
        <Icon name='checkmark' /> Yes
      </Button>
    </Modal.Actions>
  </Modal>

 
    
      </section>
    )
  }
}


export default ShuffleModal
