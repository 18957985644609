import React from 'react';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';
import { Label } from 'semantic-ui-react'


class LabelMenu extends React.Component {

  
    render() {
      const { items, refine, createURL, isRefined, currentRefinement} = this.props
  
      return (
        <>
        {currentRefinement ? (
            
            items.map(item => (

                item.items && (

                    item.items.map(item => (

                        <Label basic key={item.label}
                        onClick={event => {
                          event.preventDefault();
                          refine(item.value);
                        }}
                      >
                        {item.label}
                        
                        <Label.Detail key={item.label}>{item.count}</Label.Detail> 
                        </Label> 


                    ))
               
                 )
          
               ))
        
            
    
           ): (items.map(item => (
            <Label basic key={item.label}
                onClick={event => {
                  event.preventDefault();
                  refine(item.value);
                }}
              >
                {item.label}
                
                <Label.Detail key={item.label}>{item.count}</Label.Detail> 
                </Label>
             )
      
           )) }


          
          </>
      )
    }
  }
  




const CustomHM = connectHierarchicalMenu(LabelMenu);


export default CustomHM;
