import React, { useState, useRef, useEffect} from 'react';
import {
  InstantSearch,
  HierarchicalMenu,
  RefinementList,
  Pagination,
  ClearRefinements,
  Highlight,
  Hits,
  Panel,
  Configure,
  SearchBox,
  Snippet,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  ClearFiltersMobile,
  //PriceSlider,
  NoResults,
  //Ratings,
  ResultsNumberMobile,
  SaveFiltersMobile,
} from './widgets';
import CustomRefinements from './CurrentRefine'
//import withURLSync from './URLSync';
//import { formatNumber } from './utils';
import './Theme.css';
//import './App.css';
import './Shop.css'
import './App.mobile.css';
import './widgets/Pagination.css';
import { Icon, Loader, Label } from 'semantic-ui-react'
import BreadcrumbMenu from './Breadcrumb'
import qs from 'qs'
//import CustomHM from './CustomHM'
import SvgCompo from './svgCompo'
import { Link, NavLink } from "react-router-dom";






const searchClient = algoliasearch(
  'V522R626PP',
  'fbd0330949a32096e0d8b356f7cb201e'
);
 
  
  

  
  
  
  
  const Hit = ({ hit }) => (
    <Link to={`/product/${encodeURIComponent(hit.productBrand)}/${encodeURIComponent(hit.checkOutUrl.shopId)}/${encodeURIComponent(hit.checkOutUrl.handle)}/${hit.IDs.variant}`}>
    <article className="hit">
      <header className="hit-image-container">
        <img src={hit.variantImage} alt={hit.productName} className="hit-image" />
      </header>
  
      <div className="hit-info-container">
        <p className="hit-category">{(hit.variantColor === hit.productName) ? "": hit.variantColor}</p>
        <h1>
          <Highlight attribute="productName" tagName="mark" hit={hit} />
        </h1>
        <p className="hit-description">
          <Snippet attribute="productDescription" tagName="mark" hit={hit} />
        </p>
  
        <footer>
          <p>
            <span className="hit-em">$</span>{' '}
            <strong>{Number(hit.variantPrice).toFixed(2)}</strong>
          </p>
        </footer>
      </div>
    </article>
    </Link>
  );
  
  
  const Shop = props => {
    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const [searchState, setSearchState] = useState({});
    const [collIndex, setCollIndex] = useState(null)



    




    


    useEffect(() => {
      
      let collectionIndex = -1
      let shopIndex = -1
    
     if (props.collections.length >= 1 && collIndex === null) {
       console.log("running loop")
       for(var i = 0; i < props.collections.length; i++) {
         if (props.collections[i]._id.equals(props.match.params.collectionId)) {
          collectionIndex = i;
          setCollIndex(collectionIndex)
           break;
    }
}
     }

     if (collIndex !== null && (props.collections[collIndex].shops[props.match.params.index].shopName !== decodeURIComponent(props.match.params.current))) {
       console.log(" No Match Running Shop LOop ")
      for(var y = 0; y < props.collections[collIndex].shops.length ; y++) {
        if (props.collections[collIndex].shops[y].shopName === props.match.params.current) {
         shopIndex = y;
         props.location.search !== "" ? 
         props.history.push({ pathname: `/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[shopIndex].shopName)}/${shopIndex}`, search: props.location.search}) : 
         props.history.push(`/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[shopIndex].shopName)}/${shopIndex}`) 

          break;
        }
        } 

     }




    });

    //console.log(props)
  
  
  
    function openFilters() {
      document.body.classList.add('filtering');
      window.scrollTo(0, 0);
      window.addEventListener('keyup', onKeyUp);
      window.addEventListener('click', onClick);
    }
  
    function closeFilters() {
      document.body.classList.remove('filtering');
      containerRef.current.scrollIntoView();
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('click', onClick);
    }
  
    function onKeyUp(event) {
      if (event.key !== 'Escape') {
        return;
      }
  
      closeFilters();
    }
  
    function onClick(event) {
      if (event.target !== headerRef.current) {
        return;
      }
  
      closeFilters();
    }

 

    function searchStateChange (searchstate) {


        setSearchState(searchstate);
      }

      function switchShop (direction) {
        let indexNum = Number(props.match.params.index)


        let hierarchicalMenu = {
          "hierarchicalCategories.lvl0": props.location.search !== "" ? qs.parse(props.location.search.slice(1)).filter : ""
        }
        let query = ""

        let page = 1

        if (direction === "next"){

          
          if (indexNum + 1 <= props.collections[collIndex].shops.length - 1) {
            console.log(`Going to increment to ${indexNum + 1} and length is ${props.collections[collIndex].shops.length}`)
            props.location.search !== "" ? 
            (props.history.push({pathname: `/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[indexNum + 1].shopName)}/${indexNum + 1}`, search: props.location.search }))
            :
            (props.history.push(`/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[indexNum + 1].shopName)}/${indexNum + 1}`))
          
          }else{
           // console.log(`Did not increment because ${indexNum + 1} and length is ${props.collections[collIndex].shops.length} `)
            //onsole.log(`Resetting to 0 because reached end `)

            (props.history.push(`/shuffle`))


            /*
            props.location.search !== "" ? 
            (props.history.push({ pathname: `/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[0].shopName)}/0`, search: props.location.search})
            ) : 
            (props.history.push(`/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[0].shopName)}/${0}`))
            */
          }
      

        } else {
          if (indexNum !== 0) {
            props.location.search !== "" ? 
            (props.history.push({pathname: `/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[indexNum - 1].shopName)}/${indexNum - 1}`, search: props.location.search }))
            :
            (props.history.push(`/shop/${props.match.params.collectionId}/${encodeURIComponent(props.collections[collIndex].shops[indexNum - 1].shopName)}/${indexNum - 1}`))
          console.log (`going back because shop counter is ${indexNum}`)
          //props.history.push(`/shop/${props.match.params.collectionId}/${props.collections[collIndex].shops[indexNum - 1]._id}/${indexNum - 1}`)
         
          }else{
            console.log(`Did not go back because ${indexNum - 1} and length is ${props.collections[collIndex].shops.length} `)

          }
      
        }

      

      //setSearchState(newSt)

        
        setSearchState((prevState) => ({
          ...prevState,
          hierarchicalMenu,
          query,
          page
        }));
        
       
         
        }


  
   return (

    <div className="wrapper">
    <nav className="nav-side" role='navigation'>
    <NavLink to="/"><img className="side-logo" src="https://mixcart.s3.us-east-2.amazonaws.com/mixcartlogo.svg"/></NavLink>
    <div className="inactive-icon-div"><NavLink exact to="/search" activeClassName="active-nav-icon"><i className="fas fa-search"></i></NavLink></div> 
    <div className="inactive-icon-div"><NavLink exact to="/shuffle" activeClassName="active-nav-icon"><i className="fas fa-random"></i></NavLink></div> 
    <div className="logout-icon-div"><Label size="small" basic >BETA</Label></div>
    </nav>


       
    <section className="section-shop">
      <InstantSearch
        searchClient={searchClient}
        indexName="MXPROD"
        searchState={searchState}
        onSearchStateChange={searchStateChange}
      >
    <nav className="navbar">
    <div className="navbar-contain">
    <button className="nav-next-shop" onClick={() => switchShop("previous")}><Icon name="arrow alternate circle left" size="large"></Icon> Previous Shop</button>
      </div>
      <div className="nav-middle">
    {collIndex !== null ? <BreadcrumbMenu className="bread-crumb" shopName={props.collections[collIndex].shops[props.match.params.index].shopName} collectionName={props.collections[collIndex].collectionName}  /> : null}
    </div>
      <div className="navbar-contain">
      <button className="nav-next-shop" onClick={() => switchShop("next")}>Next Shop <Icon name="arrow alternate circle right" size="large"></Icon></button>
      </div>
  </nav>
  <div className="searchbelow">
  <div className="searchbelow-img-container">
  {collIndex !== null ? ( props.collections[collIndex].shops[props.match.params.index].logoSrc ? (<img src={props.collections[collIndex].shops[props.match.params.index].logoSrc} alt="logo" className="searchbelow-image" />)
  :(<SvgCompo fill={props.collections[collIndex].shops[props.match.params.index].logoSvg.fill} viewBox={props.collections[collIndex].shops[props.match.params.index].logoSvg.viewBox} d={props.collections[collIndex].shops[props.match.params.index].logoSvg.d} d2={props.collections[collIndex].shops[props.match.params.index].logoSvg.d2 ? props.collections[collIndex].shops[props.match.params.index].logoSvg.d2 : null} 
  fill2={props.collections[collIndex].shops[props.match.params.index].logoSvg.fill2 ? props.collections[collIndex].shops[props.match.params.index].logoSvg.fill2 : null}/>)) : (<Loader active inline />)} 
  {collIndex !== null ? (<h4><i className="fas fa-map-marker-alt"></i> &nbsp;{props.collections[collIndex].shops[props.match.params.index].location}</h4>) : null} 
      </div>
   
       <SearchBox
            translations={{
              placeholder: `Search ${ collIndex !== null ? props.collections[collIndex].shops[props.match.params.index].shopName: "Search"} `,
            }}
            submit={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 18 18"
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1."
                  transform="translate(1 1)"
                >
                  <circle cx="7.11" cy="7.11" r="7.11" />
                  <path d="M16 16l-3.87-3.87" />
                </g>
              </svg>
            }
          />
          
          

          
        
          </div>
         
          

        <Configure
        filters={`productBrand:"${decodeURIComponent(props.match.params.current)}"`}
          //filters={`productBrand:${props.collections[props.match.params.index] ? (props.collections[props.match.params.index].shops[shopCounter].shopId):(props.match.params.first)}`}
          removeWordsIfNoResults="allOptional"
          hitsPerPage={36}
        />
  
        <main className="container" ref={containerRef}>
          <div className="container-wrapper">
            <section className="container-filters" onKeyUp={onKeyUp}>
              <div className="container-header">
                <h2>Filters</h2>
  
                <div className="clear-filters" data-layout="desktop">
                  <ClearRefinements
                    translations={{
                      reset: (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                          >
                            <g fill="none" fillRule="evenodd" opacity=".4">
                              <path d="M0 0h11v11H0z" />
                              <path
                                fill="#000"
                                fillRule="nonzero"
                                d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                              />
                            </g>
                          </svg>
                          Clear filters
                        </>
                      ),
                    }}
                  />
                </div>
  
                <div className="clear-filters" data-layout="mobile">
                  <ResultsNumberMobile />
                </div>
              </div>
  
              <div className="container-body">
                <Panel header="Category">
                  <HierarchicalMenu
                    attributes={[
                      'hierarchicalCategories.lvl0',
                      'hierarchicalCategories.lvl1',
                      'hierarchicalCategories.lvl2',
                    ]}
                    limit={20}
                    defaultRefinement={props.location.search !== "" ? qs.parse(props.location.search.slice(1)).filter : null}
                    rootPath={props.location.search !== "" ? qs.parse(props.location.search.slice(1)).filter : null}

                  //extraFilter={props.location.search !== "" ? qs.parse(props.location.search.slice(1)).filter: null}
                  
                  />
                </Panel>
  
                <Panel header="Brands">
                  <RefinementList
                    attribute="productBrand"
                    searchable={true}
                    translations={{
                      placeholder: 'Search for brands…',
                    }}
                  />
                </Panel>

              
  
  
               
              </div>
            </section>
  
            <footer className="container-filters-footer" data-layout="mobile">
              <div className="container-filters-footer-button-wrapper">
                <ClearFiltersMobile containerRef={containerRef} />
              </div>
  
              <div className="container-filters-footer-button-wrapper">
                <SaveFiltersMobile onClick={closeFilters} />
              </div>
            </footer>
          </div>
  
          <section className="container-results">
            <header className="container-header container-options">
              <CustomRefinements/>
              
  
           
            </header>
  
            <Hits hitComponent={Hit}/>
            <NoResults />
  
            <footer className="container-footer">
              <Pagination
                padding={2}
                showFirst={false}
                showLast={false}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  ),
                }}
              />
            </footer>
          </section>
        </main>
  
        <aside data-layout="mobile">
          <button
            className="filters-button"
            data-action="open-overlay"
            onClick={openFilters}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
              <path
                d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
                stroke="#fff"
                strokeWidth="1.29"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Filters
          </button>
        </aside>
       
      </InstantSearch>
      </section>
      </div>
      
      
    );
  };
  
  export default Shop;
  
  /*
  <div className="searchbelow-img-container">
        <img src={shopsData[shopCounter].shopLogo} alt="tres" className="searchbelow-image" />
        <h4><i className="fas fa-map-marker-alt"></i> &nbsp;{shopsData[shopCounter].shopLocation}</h4>
      </div>*/

      /*
       if (indexNum + 1 <= props.collections[collIndex].shops.length - 1 && shopCounter < props.collections[collIndex].shops.length - 1) {
          console.log(`Going to increment to ${indexNum + 1} and length is ${props.collections[collIndex].shops.length}`)
        props.history.push(`/shop/${props.match.params.collectionId}/${props.collections[collIndex].shops[indexNum + 1]._id}/${indexNum + 1}`)
        setShopCounter(shopCounter + 1)
        }else{
          console.log(`Did not increment because ${indexNum + 1} and length is ${props.collections[collIndex].shops.length} `)
        }*/
        
     