import React from 'react';
import PropTypes from 'prop-types'
import './App.css';
import Login from './Login'
import Feed from './Feed'
import Shop from './Shop'
import ShuffleModal from './Shuffle'
import RedirectCompo from './Redirect'
import SearchPage from './Search'


import {
  Stitch,
  UserPasswordCredential,
  FacebookRedirectCredential,
  GoogleRedirectCredential,
  RemoteMongoClient,
} from 'mongodb-stitch-browser-sdk'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
//import HomepageLayout from './Homepage';
import Home from './Home'
import ProductDetail from './ProductDetail';


class StitchApp extends React.Component {
  static propTypes = {
    appId: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.appId = props.appId
    this.client = Stitch.initializeDefaultAppClient(this.appId)

    const isAuthed = this.client.auth.isLoggedIn
    this.state = {
      isAuthed,
      collections: [],
      filteredColl : [],
    }

    this.mongodb = this.client.getServiceClient(
      RemoteMongoClient.factory,
      'mongodb-atlas'
    )
  }

  componentDidMount() {
    if (this.client.auth.hasRedirectResult()) {
      this.client.auth.handleRedirectResult().then(user => {
        this.setState({ isAuthed: this.client.auth.isLoggedIn })
      })
    }

    if (this.client.auth.isLoggedIn) {
      this.getCollections()
    }
  }

  login = async (type, { email, password } = {}) => {
    const { isAuthed } = this.state
    let credential

    if (isAuthed) {
      return
    }

    if (type === 'facebook') {
      credential = new FacebookRedirectCredential()
      this.client.auth.loginWithRedirect(credential)
    } else if (type === 'google') {
      credential = new GoogleRedirectCredential()
      this.client.auth.loginWithRedirect(credential)
    } else {
      credential = new UserPasswordCredential(email, password)
      await this.client.auth.loginWithCredential(credential)
      this.setState({ isAuthed: true })
    }
  }

  logout = async () => {
    this.client.auth.logout()
    this.setState({ isAuthed: false })
  }

  getCollections = async () => {
    console.log("Get Collections mongo")
    //return new Promise(resolve => {
      this.mongodb
      .db('collections')
      .collection('allCollections')
      .find({})
      .asArray()
      .then(collections => {
        this.setState({ collections: collections })
        //resolve("done")
        console.log("done setting state colelctions")
      //})  
    });
  }

  getShop = async (shopName) => {

    this.mongodb
      .db('shopCollections')
      .collection('allCollections')
      .findOne({ _id : shopName })
      .then(shop => {
        this.setState({ shop: shop })
      })
  }

  filterCollection = async (filter) => {

    let filteredColl = this.state.collections

    let results = [];
    for(var i=0; i< filteredColl.length; i++) {
    
     if(filteredColl[i].tags.includes(filter)){
    results.push(filteredColl[i])
  }
}

    this.setState({ filteredColl : results })

    
  }





  render() {
    const { isAuthed, shop, collections } = this.state
    return (
        <>
        
          
          <Router>
<Switch>
          <Route path="/" exact render={(props) => <Home {...props} />}/>
          {isAuthed ? (
         <>
          <Route path="/shuffle" exact render={(props) => <Feed {...props} collections={this.state.collections} filteredColl={this.state.filteredColl} filterCollection={this.filterCollection} getColl={this.getCollections}/>}/>
          <Route path="/pop" exact render={(props) => <ShuffleModal {...props} collections={this.state.collections}/>}/>
          <Route path="/shop/:collectionId/:current/:index"   render={(props) => <Shop {...props} collections={collections} getShop={this.getShop}/>}/>
          <Route path="/redirect/:redirectUrl"   render={(props) => <RedirectCompo {...props}/>}/>
          <Route path="/search"  exact render={(props) => <SearchPage {...props}/>}/>
          <Route path="/product/:brand/:shop/:handle/:variant" render={(props) => <ProductDetail {...props}/>}/>

            </>
          ) : (
            <Login loginUser={this.login} />
          )}

</Switch>
        
          </Router>
     
          
        
        </>
    )
  }
}

export default StitchApp;

 