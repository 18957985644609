import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Segment, Form, Icon, Message, Menu, Container, Image} from 'semantic-ui-react'

class Login extends Component {
  static propTypes = {
    loginUser: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      userInput: {
        email: '',
        password: ''
      },
      errorMessage: ''
    }
  }

  handleChange = (e, { name, value }) => {
    return this.setState(prevState => ({
      userInput: { ...prevState.userInput, [name]: value }
    }))
  }

  render() {
    const {
      errorMessage,
      userInput: { email, password }
    } = this.state

    const { loginUser } = this.props

    return (
        
        <div className="login-page">
        <Menu borderless stackable>
        <div className="login-logo-div">
        <img className="login-logo" src="https://shuffleimg.s3.us-east-2.amazonaws.com/mxlogo1.svg" alt='smallogo' />
        </div>
      </Menu>
    <Container>
  
    <h2 className="login-h1">Alpha Test 2.0</h2>
      <Segment.Group>
        <Segment>
          <Form
            onSubmit={() => {
              loginUser('email', { email, password }).catch(err => {
                this.setState({ errorMessage: err.message })
              })
            }}
          >
            <Form.Input
              fluid
              label="Email"
              name="email"
              placeholder="someone@example.com"
              defaultValue={email}
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              label="Password"
              name="password"
              type="password"
              placeholder="password"
              defaultValue={password}
              onChange={this.handleChange}
            />
            {errorMessage && (
              <Message negative header="Login Failed" content={errorMessage} />
            )}
            <Form.Button type="submit" primary>
              <Icon name="log out" />Log In
            </Form.Button>
          </Form>
        </Segment>
      </Segment.Group>
      </Container>
      </div>
    )
  }
}

export default Login
